import * as React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  letterScore: {
    fontFamily: 'Roboto Mono',
    fontSize: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface LetterScoreProps {
  score: number;
}

export default function LetterScore({ score }: LetterScoreProps) {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" spacing={1} className={classes.root}>
      <Grid item xs={4} />
      <Grid item xs={4} />
      <Grid item xs={4}>
        <Paper className={classes.letterScore} data-testid="word-score">
          {score}
        </Paper>
      </Grid>
    </Grid>
  );
}
