import { IS_MOBILE } from './util/platform';

export enum AppFlavour {
  Free = 'free',
  Pro = 'pro',
}

type AppConfig = {
  flavour: AppFlavour;
  isFreeFlavour: boolean;
  isMobileAdsEnabled: boolean;
  isProFlavour: boolean;
  version: string;
  privacyPolicyUrl: string;
  mobile: {
    adMob: {
      bannerAdBottomSpacePx: number;
      bannerAdId: string;
      interstitialAdId: string;
      playedWordsBeforeAd: number;
      testDeviceIds: string[];
    };
  };
};

type Config = {
  app: AppConfig;
};

const appFlavour = process.env.REACT_APP_APP_FLAVOUR as AppFlavour;
const isFreeFlavour = appFlavour === AppFlavour.Free;
const isProFlavour = !isFreeFlavour;
const isMobileAdsEnabled = IS_MOBILE && isFreeFlavour;

if (process.env.NODE_ENV === 'development') {
  console.log('👾', 'appFlavour', appFlavour);
  console.log('👾', 'isFreeFlavour', isFreeFlavour);
  console.log('👾', 'isMobileAdsEnabled', isMobileAdsEnabled);
}

const config: Config = {
  app: {
    flavour: appFlavour,
    isFreeFlavour,
    isMobileAdsEnabled,
    isProFlavour,
    version: process.env.REACT_APP_GIT_SHA,
    privacyPolicyUrl: isFreeFlavour
      ? 'https://anotador-puntaje-scrabble.salzotech.com/doc/legal/privacy-policy-en.html'
      : 'https://anotador-puntaje-scrabble.salzotech.com/doc/legal/privacy-policy-en-pro.html',
    mobile: {
      adMob: {
        bannerAdBottomSpacePx: isMobileAdsEnabled ? 50 : 0,
        interstitialAdId: process.env.REACT_APP_MOBILE_ADMOB_INTERSTITIAL_ID,
        bannerAdId: process.env.REACT_APP_MOBILE_ADMOB_BANNER_ID,
        playedWordsBeforeAd: 20,
        testDeviceIds: (
          process.env.REACT_APP_MOBILE_ADMOB_DEVICE_IDS || ''
        ).split(','),
      },
    },
  },
};

export default config;
