import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { gaEvent } from '../util/ga';
import { Player } from '../models';
import config from '../config';
import PlayerSettings from '../components/PlayerSettings';
import PlayerWords from '../components/PlayerWords';

interface PlayerSettingsProps {
  players: Player[];
  onClose: () => void;
  // could be more grannular (player x changed...)
  onPlayersChange: (players: Player[]) => void;
  onPlayerDeletePlayedWord: (
    playerNumber: number,
    playedWordNumber: number
  ) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '8px',
    marginTop: theme.spacing(1),
    backgroundColor: 'white',
    // make room for the AdMob banner bottom ad (if any)
    paddingBottom: `${
      config.app.mobile.adMob.bannerAdBottomSpacePx + theme.spacing(1)
    }px`,
  },
  actionsCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionsPanel: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}));

function PlayersSettings({
  players,
  onClose,
  onPlayersChange,
  onPlayerDeletePlayedWord,
}: PlayerSettingsProps) {
  const { t } = useTranslation();
  const [selectedPlayer, setSelectedPlayer] = useState<number>(0);

  const classes = useStyles();

  // screen state
  const handleSelectPlayer = (playerNo: number) => setSelectedPlayer(playerNo);

  // data state
  const handlePlayerChange = (player: Player, playerNumber: number) => {
    const newPlayers = [...players];
    newPlayers[playerNumber] = player;
    onPlayersChange(newPlayers);
    gaEvent('action_change_player');
  };
  const handleDeletePlayerPlayedWord = (
    playerNumber: number,
    playedWordNumber: number
  ) => {
    onPlayerDeletePlayedWord(playerNumber, playedWordNumber);
    gaEvent('action_delete_word');
  };

  const isWinning = (player: Player) =>
    players
      .filter((p) => p.name !== player.name)
      .every((otherPlayer) => otherPlayer.currentScore < player.currentScore);

  return (
    <Container className={classes.root}>
      <Typography variant="h5" align="center" gutterBottom>
        {t('playersSettings.title')}
      </Typography>
      <TableContainer component={Paper}>
        <form>
          <Table aria-label="Players table" size="medium" padding="checkbox">
            <TableBody>
              {players.map((player, i) => (
                <TableRow
                  key={player.name}
                  onClick={() => handleSelectPlayer(i)}
                  selected={selectedPlayer === i}
                >
                  <TableCell>
                    <Radio
                      checked={selectedPlayer === i}
                      onChange={(event) =>
                        handleSelectPlayer(parseInt(event.target.value, 10))
                      }
                      value={i}
                      name="players-radio-button-group"
                      inputProps={{ 'aria-label': `P${i}` }}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {player.displayName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{player.name}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      {isWinning(player) && '🏆'}
                      {player.currentScore}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </form>
      </TableContainer>
      <PlayerSettings
        player={players[selectedPlayer]}
        onPlayerChange={(player) => handlePlayerChange(player, selectedPlayer)}
      />
      {players[selectedPlayer].playedWords.length >= 5 && (
        <div className={classes.actionsPanel}>
          <Button variant="contained" onClick={onClose}>
            {t('playersSettings.back')}
          </Button>
        </div>
      )}
      <PlayerWords
        player={players[selectedPlayer]}
        onDeletePlayedWord={(playedWordNumber) =>
          handleDeletePlayerPlayedWord(selectedPlayer, playedWordNumber)
        }
      />
      <div className={classes.actionsPanel}>
        <Button variant="contained" onClick={onClose}>
          {t('playersSettings.back')}
        </Button>
      </div>
    </Container>
  );
}

export default PlayersSettings;
