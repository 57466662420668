import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import googlePlayBadgeImg from '../../images/google-play-badge.png';
import scrabbleScorerIconImg from '../../images/scrabble-scorer-icon-128x128-indexed.png';

type AboutModalProps = {
  open: boolean;
  onClose: MouseEventHandler | undefined;
};

const useStyles = makeStyles((theme) => ({
  downloadLinksHolder: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
}));

export default function RemoveAdsModal({ open, onClose }: AboutModalProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="remove-ads-dialog-title"
      aria-describedby="remove-ads-dialog-description"
    >
      <DialogTitle id="remove-ads-dialog-title">
        {t('removeAds.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="remove-ads-dialog-description">
          {t('removeAds.body')}
        </DialogContentText>
        <DialogContent className={classes.downloadLinksHolder}>
          <a href="https://play.google.com/store/apps/details?id=com.salzotech.apps.scrabbleScoreKeeper.es.v1.pro">
            <img
              src={scrabbleScorerIconImg}
              style={{ maxWidth: '40%', borderRadius: '20%' }}
              alt="Logo de Anotador Puntaje Scrabble Pro"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.salzotech.apps.scrabbleScoreKeeper.es.v1.pro">
            <img
              src={googlePlayBadgeImg}
              style={{ maxWidth: '90%' }}
              alt="Get it on Google Play"
            />
          </a>
        </DialogContent>
        <DialogContentText
          id="remove-ads-dialog-description"
          align="center"
          style={{ marginBottom: 0 }}
        >
          {t('removeAds.thanks')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
