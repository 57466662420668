import { SpeechRecognition } from '@capacitor-community/speech-recognition';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguage } from '../../i18n';
import {
  UseSpeechRecognitionHookProps,
  UseSpeechRecognitionHookReturn,
} from './types';

// This promise is never fulfilling
// https://developer.android.com/reference/android/speech/SpeechRecognizer#stopListening()
// "Note that in the default case, this does not need to be called, as the speech endpointer
// will automatically stop the recognizer listening when it determines speech has completed..."
// const stopListening = async () => await SpeechRecognition.stop();
const stopListening = async () => Promise.resolve();

const isAvailable = async () => {
  const result = await SpeechRecognition.available();
  return result.available;
};

export default function useSpeechRecognitionNative({
  onListeningEnd,
  onListeningError,
}: UseSpeechRecognitionHookProps): UseSpeechRecognitionHookReturn {
  const { t } = useTranslation();
  const prompt = t('speechRecognition.prompt');

  const handleError = onListeningError;

  const handleOnEnd = (matches: string[]) => {
    const word = matches[0] ? matches[0].replace(' ', '') : '';
    onListeningEnd(word);
  };

  const startListening = async () => {
    const { permission } = await SpeechRecognition.hasPermission();
    if (!permission) {
      // The requestPermission method simply returns a promise of void immediately...
      // https://github.com/capacitor-community/speech-recognition/issues/31
      await SpeechRecognition.requestPermission();
      onListeningError(new Error('permissionrequestinteraction'));
    } else {
      return SpeechRecognition.start({
        language: getCurrentLanguage(),
        maxResults: 1,
        prompt,
        partialResults: false,
        // popup: true,
        // Can't use the ideal popup because:
        // Results promise not being executed for android auto stop with popup #29
        // https://github.com/capacitor-community/speech-recognition/issues/29
        popup: false,
      })
        .then(({ matches }) => handleOnEnd(matches))
        .catch(handleError);
    }
  };

  return {
    isAvailable,
    startListening,
    stopListening,
  };
}
