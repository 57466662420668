/* eslint-disable import/no-extraneous-dependencies */
import 'regenerator-runtime/runtime';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import MicIcon from '@material-ui/icons/Mic';
import MicNoneIcon from '@material-ui/icons/MicNone';

import { gaEvent } from '../util/ga';
import useSpeechRecognition from '../services/speechRecognition/useSpeechRecognition';

interface WordSpeechInputControlProps {
  onWordSpoken: (word: string) => void;
  onListeningError: (error: any) => void;
}

export default function WordSpeechInputControl({
  onWordSpoken,
  onListeningError,
}: WordSpeechInputControlProps) {
  const { t } = useTranslation();

  const [isListening, setListening] = useState(false);

  const handleListeningEnd = (word: string) => {
    setListening(false);
    if (word) onWordSpoken(word);
  };

  const handleListeningError = (error: Error) => {
    setListening(false);

    // https://github.com/capacitor-community/speech-recognition/blob/master/android/src/main/java/com/getcapacitor/community/speechrecognition/SpeechRecognition.java#L395
    const errorMessage: string = error.message || '';
    const errorMessagei18nCode = errorMessage
      .toLocaleLowerCase()
      .replaceAll(' ', '');

    let translatedErrorMessage = '';
    translatedErrorMessage = t(
      `speechRecognition.errors.${errorMessagei18nCode}`
    );

    if (errorMessagei18nCode === 'permissionrequestinteraction') {
      onListeningError(new Error(translatedErrorMessage));
      return;
    }

    if (translatedErrorMessage) {
      translatedErrorMessage = `${translatedErrorMessage}. ${t(
        'speechRecognition.pleaseTryAgain'
      )}.`;
    }
    const finalError = translatedErrorMessage
      ? new Error(translatedErrorMessage)
      : error;
    onListeningError(finalError);
  };

  const { isAvailable, startListening, stopListening } = useSpeechRecognition({
    onListeningEnd: handleListeningEnd,
    onListeningError: handleListeningError,
  });

  if (!isAvailable) return <></>;

  const handleToggleRecording = async () => {
    gaEvent('microphone_use');
    if (isListening) {
      await stopListening();
      setListening(false);
    } else {
      startListening();
      setListening(true);
    }
  };

  return (
    <IconButton onClick={handleToggleRecording} aria-label="toggle listening">
      {isListening ? (
        <MicIcon fontSize="large" />
      ) : (
        <MicNoneIcon fontSize="large" />
      )}
    </IconButton>
  );
}
