import * as React from 'react';

import Grid from '@material-ui/core/Grid';

import LetterScorer from './LetterScorer';
import { LetterValues, Multiplier, PlayedLetter } from '../models';
// import LetterScorerTotal from './LetterScorerTotal';

interface LetterScorerListProps {
  letterValues: LetterValues;
  playedLetters: PlayedLetter[];
  // score: number;
  onMultiplierChange: (i: number, multiplier: Multiplier) => void;
}

export default function LetterScorerList({
  letterValues,
  playedLetters,
  // score,
  onMultiplierChange,
}: LetterScorerListProps) {
  return (
    <Grid container alignItems="center" spacing={1}>
      {/* <LetterScorerTotal score={score} /> */}
      {playedLetters.map((playedLetter, i) => (
        <LetterScorer
          playedLetter={playedLetter}
          letterValues={letterValues}
          onMultiplierChange={(multiplier) => onMultiplierChange(i, multiplier)}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        />
      ))}
    </Grid>
  );
}
