/* eslint-disable import/no-extraneous-dependencies */
import 'regenerator-runtime/runtime';

import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import {
  UseSpeechRecognitionHookProps,
  UseSpeechRecognitionHookReturn,
} from './types';
import { getCurrentLanguage } from '../../i18n';

const stopListening = async () =>
  Promise.resolve(SpeechRecognition.stopListening());

const isAvailable = async () =>
  Promise.resolve(SpeechRecognition.browserSupportsSpeechRecognition());

export default function useSpeechRecognitionWeb({
  onListeningEnd,
}: UseSpeechRecognitionHookProps): UseSpeechRecognitionHookReturn {
  const { transcript, resetTranscript } = useSpeechRecognition();

  const handleOnEnd = () => {
    const word = transcript ? transcript.replace(' ', '') : '';
    onListeningEnd(word);
  };

  const startListening = async () => {
    resetTranscript();
    // 'continuous: false' would be great because as soon as you say a word and stop,
    // the microphone auto stop listening and give you the word. Unfortunately, it's
    // not working on Chrome on Android.
    // Ref: React speech recognition does not work in chrome for mobile #89
    // https://github.com/JamesBrill/react-speech-recognition/issues/89
    // Maybe use this new feature
    // https://github.com/JamesBrill/react-speech-recognition/releases/tag/v3.8.0
    // to enable 'continuous: false' on desktop and disable it on mobile.
    return await SpeechRecognition.startListening({
      continuous: false,
      language: getCurrentLanguage(),
    });
  };

  const recognition = SpeechRecognition.getRecognition() as any;
  if (recognition) {
    // This was just because on unit tests, recognition is null
    recognition.onend = handleOnEnd;
  }

  return {
    isAvailable,
    startListening,
    stopListening,
  };
}
