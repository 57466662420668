import {
  Multiplier,
  PlayedLetter,
  PlayedWord,
  Player,
  ScrabbleScorerState,
  ScreenState,
  Settings,
} from '../../models';
import { calculateWordsScore } from './scrabbleLogic';
import {
  createPlayedWord,
  createPlayedWordFromPreviousWithNewLetterMultiplier,
  createPlayedWordFromPreviousWithNewMultiplier,
  createPlayer,
} from './scrabbleUtils';

// This won't ever change
export const MIN_PLAYER_NUMBER = 2;
export const MAX_PLAYER_NUMBER = 4;

export const APP_LOCAL_STORAGE_KEY = 'scrabbleScorerState';
export const APP_LOCAL_STORAGE_SETTINGS_KEY = 'scrabbleScorerSettings';

const initialCurrentlyPlayedWord: PlayedWord = {
  letters: [],
  score: 0,
  multiplier: Multiplier.NONE,
};

export const initialState = (
  playerDefaultName: string,
  playerDefaultDisplayNameInitial: string
): ScrabbleScorerState => ({
  players: [
    {
      name: `${playerDefaultName} 1`,
      displayName: `${playerDefaultDisplayNameInitial}1`,
      currentScore: 0,
      playedWords: [],
      hasPlayedInCurrentTurn: false,
    },
    {
      name: `${playerDefaultName} 2`,
      displayName: `${playerDefaultDisplayNameInitial}2`,
      currentScore: 0,
      playedWords: [],
      hasPlayedInCurrentTurn: false,
    },
  ],
  currentlyPlayedWord: initialCurrentlyPlayedWord,
  screenState: ScreenState.MAIN_SCREEN,
  lastAction: null,
});

export const initialSettings: Settings = {
  isSoundEnabled: true,
  isPlayerScoreBadgeEnabled: true,
  // language: 'en', // BUG!!!
};

const createStateWithNewPlayedWord = (
  newPlayedWord: PlayedWord,
  previousState: ScrabbleScorerState
): ScrabbleScorerState => ({
  ...previousState,
  currentlyPlayedWord: newPlayedWord,
});

const getStateWithNewPlayedWord =
  (word: string) => (state: ScrabbleScorerState) => {
    const playedWord = createPlayedWord(
      word,
      state.currentlyPlayedWord.letters
    );
    const newState = createStateWithNewPlayedWord(playedWord, state);
    return newState;
  };

const getStateWithDeletedPlayerPlayedWord =
  (playerNumber: number, playedWordNumber: number) =>
  (state: ScrabbleScorerState) => {
    const newState = { ...state };
    const playedWordPlayer = newState.players[playerNumber];
    const newPlayerPlayedWords = [...playedWordPlayer.playedWords];
    newPlayerPlayedWords.splice(playedWordNumber, 1);
    const player = newState.players[playerNumber];
    player.playedWords = newPlayerPlayedWords;
    player.currentScore = calculateWordsScore(newPlayerPlayedWords);
    return newState;
  };

const getStateWithNewWordMultiplier =
  (newMultiplier: Multiplier) => (state: ScrabbleScorerState) => {
    const playedWord = createPlayedWordFromPreviousWithNewMultiplier(
      newMultiplier,
      state.currentlyPlayedWord
    );
    const newState = createStateWithNewPlayedWord(playedWord, state);
    return newState;
  };

const getStateWithNewLetterMultiplier =
  (letterIndex: number, newMultiplier: Multiplier) =>
  (state: ScrabbleScorerState) => {
    const playedWord = createPlayedWordFromPreviousWithNewLetterMultiplier(
      state.currentlyPlayedWord,
      letterIndex,
      newMultiplier
    );
    const newState = createStateWithNewPlayedWord(playedWord, state);
    return newState;
  };

const getStateWithNewPlayer = (
  state: ScrabbleScorerState
): ScrabbleScorerState => {
  const currentNumberOfPlayers = state.players.length;
  if (currentNumberOfPlayers === MAX_PLAYER_NUMBER) return state;
  const newPlayer = createPlayer(currentNumberOfPlayers + 1);
  const players = [...state.players, newPlayer];
  const newState: ScrabbleScorerState = {
    ...state,
    players,
    currentlyPlayedWord: {
      ...state.currentlyPlayedWord,
    },
  };
  return newState;
};

const getStateWithNewPlayers =
  (players: Player[]) =>
  (state: ScrabbleScorerState): ScrabbleScorerState => {
    const newState: ScrabbleScorerState = {
      ...state,
      players,
    };
    return newState;
  };

const getStateWithOneLessPlayer =
  () =>
  (state: ScrabbleScorerState): ScrabbleScorerState => {
    const currentNumberOfPlayers = state.players.length;
    if (currentNumberOfPlayers === MIN_PLAYER_NUMBER) return state;
    const players = state.players.slice(0, state.players.length - 1);
    const newState: ScrabbleScorerState = {
      ...state,
      players,
      currentlyPlayedWord: {
        ...state.currentlyPlayedWord,
      },
    };
    return newState;
  };

const getStateWithScoreAssignedToPlayer =
  (playerNumber: number) =>
  (state: ScrabbleScorerState): ScrabbleScorerState => {
    if (state.currentlyPlayedWord.score === 0) return state;

    const scorerPlayerIndex = playerNumber - 1;
    const scorerPlayer = state.players[scorerPlayerIndex];

    const newPlayerSet = [...state.players];
    const scorerPlayerWithNewScore: Player = {
      ...scorerPlayer,
      currentScore: scorerPlayer.currentScore + state.currentlyPlayedWord.score,
      playedWords: [...scorerPlayer.playedWords, state.currentlyPlayedWord],
      hasPlayedInCurrentTurn: true,
    };
    newPlayerSet[scorerPlayerIndex] = scorerPlayerWithNewScore;
    // TODO: improve this
    if (newPlayerSet.every((player) => player.hasPlayedInCurrentTurn)) {
      newPlayerSet.forEach((player) => {
        // eslint-disable-next-line no-param-reassign
        player.hasPlayedInCurrentTurn = false;
      });
    }
    const newState: ScrabbleScorerState = {
      ...state,
      players: newPlayerSet,
      currentlyPlayedWord: initialCurrentlyPlayedWord,
    };
    return newState;
  };

const joinPlayedLetters = (playedLetters: PlayedLetter[]): string =>
  playedLetters.map((playedLetter) => playedLetter.letter).join('');

export {
  getStateWithDeletedPlayerPlayedWord,
  getStateWithNewLetterMultiplier,
  getStateWithNewPlayedWord,
  getStateWithNewPlayer,
  getStateWithNewPlayers,
  getStateWithNewWordMultiplier,
  getStateWithOneLessPlayer,
  getStateWithScoreAssignedToPlayer,
  joinPlayedLetters,
};
