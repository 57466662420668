import { LetterValues } from '../models';

export enum Language {
  en = 'en',
  es = 'es',
  fr = 'fr',
  it = 'it',
  nl = 'nl',
  pt = 'pt',
}

export type TokenisationFunction = (word: string) => string[];

export type LanguageConfig = {
  letters: LetterValues;
  inputCleaningRegex: RegExp;
  tokenisationFunction: TokenisationFunction;
};

export type LanguageConfigs = {
  [key in Language]: LanguageConfig;
};
