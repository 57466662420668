/* eslint-disable react-hooks/rules-of-hooks */
// Same order always

import { IS_WEB } from '../../util/platform';
import {
  UseSpeechRecognitionHookProps,
  UseSpeechRecognitionHookReturn,
} from './types';
import useSpeechRecognitionNative from './useSpeechRecognitionNative';
import useSpeechRecognitionWeb from './useSpeechRecognitionWeb';

export default function useSpeechRecognition(
  props: UseSpeechRecognitionHookProps
): UseSpeechRecognitionHookReturn {
  if (IS_WEB) return useSpeechRecognitionWeb(props);
  return useSpeechRecognitionNative(props);
}
