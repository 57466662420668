import { ScrabbleScorerState, Settings } from '../models';

export const APP_LOCAL_STORAGE_KEY = 'scrabbleScorerState';
export const APP_LOCAL_STORAGE_SETTINGS_KEY = 'scrabbleScorerSettings';

const loadFromStorage = (key: string) => {
  const savedObjJson = localStorage.getItem(key);
  const savedObj = savedObjJson ? JSON.parse(savedObjJson) : null;
  return savedObj;
};

export const loadState = (): ScrabbleScorerState | null =>
  loadFromStorage(APP_LOCAL_STORAGE_KEY);

export const loadSettings = (): Settings | null =>
  loadFromStorage(APP_LOCAL_STORAGE_SETTINGS_KEY);

export const saveState = (state: ScrabbleScorerState) =>
  localStorage.setItem(APP_LOCAL_STORAGE_KEY, JSON.stringify(state));

export const saveSettings = (settings: Settings) =>
  localStorage.setItem(
    APP_LOCAL_STORAGE_SETTINGS_KEY,
    JSON.stringify(settings)
  );
