import * as React from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialAppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { gaEvent } from '../util/ga';
import { IS_MOBILE } from '../util/platform';
import config from '../config';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

interface AppBarProps {
  onNewGame: () => void;
  onAbout: () => void;
  onSettings: () => void;
  onRemoveAds: () => void;
}

export default function AppBar({
  onNewGame,
  onAbout,
  onSettings,
  onRemoveAds,
}: AppBarProps) {
  const { t } = useTranslation();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: { currentTarget: any }) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleNewGame = () => {
    onNewGame();
    handleClose();
    gaEvent('action_new_game');
  };
  const handleSettings = () => {
    onSettings();
    handleClose();
  };
  const handleRemoveAds = () => {
    onRemoveAds();
    handleClose();
  };
  const handleAbout = () => {
    onAbout();
    handleClose();
  };

  return (
    <MaterialAppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {t('title')} {config.app.isProFlavour ? 'Pro' : ''}
        </Typography>
      </Toolbar>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleNewGame}>{t('menu.newGame')}</MenuItem>
        <MenuItem onClick={handleSettings}>{t('menu.settings')}</MenuItem>
        {IS_MOBILE && config.app.isFreeFlavour && (
          <MenuItem onClick={handleRemoveAds}>{t('menu.removeAds')}</MenuItem>
        )}
        <MenuItem onClick={handleAbout}>{t('menu.about')}</MenuItem>
      </Menu>
    </MaterialAppBar>
  );
}
