import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import { Settings } from '../models';
import { supportedLanguages } from '../languages';

type SettingsScreenProps = {
  value: Settings;
  onChange: (newSettings: Settings) => void;
  onCancel: () => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '8px',
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: 'white',
  },
  actionsCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionsPanel: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    gap: 16,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

function SettingsScreen({ value, onChange, onCancel }: SettingsScreenProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [settings, setSettings] = useState(value);

  const handleCheckboxSettingChange = ({
    target: { name },
  }: ChangeEvent<HTMLInputElement>) =>
    // @ts-ignore
    setSettings({ ...settings, [name]: !settings[name] });

  const handleLanguageChanged = ({
    target: { value },
  }: ChangeEvent<{ name?: string; value: string }>) =>
    setSettings({ ...settings, language: value });

  const handleCancel = onCancel;
  const handleSave = () => onChange(settings);

  return (
    <Container className={classes.root}>
      <Typography variant="h5" align="center" gutterBottom>
        {t('settings.title')}
      </Typography>
      <div className={classes.root}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={settings.isSoundEnabled}
                onChange={handleCheckboxSettingChange}
                name="isSoundEnabled"
                color="primary"
              />
            }
            label={t('settings.soundEnabled')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={settings.isPlayerScoreBadgeEnabled}
                onChange={handleCheckboxSettingChange}
                name="isPlayerScoreBadgeEnabled"
                color="primary"
              />
            }
            label={t('settings.showScoreInPlayerIcon')}
          />
          <FormControl>
            <InputLabel>{t('settings.language')}</InputLabel>
            <Select value={settings.language} onChange={handleLanguageChanged}>
              {supportedLanguages.map((lang) => (
                <MenuItem value={lang.value}>{lang.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
      </div>
      <div className={classes.actionsPanel}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          {t('settings.save')}
        </Button>
        <Button variant="contained" color="secondary" onClick={handleCancel}>
          {t('settings.cancel')}
        </Button>
      </div>
    </Container>
  );
}

export default SettingsScreen;
