import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Multiplier, ScrabbleScorerState, Settings } from '../../models';
import { ScrabbleActionType } from '../../services/scrabble/scrabbleLogic';
import {
  getAssignScoreToPlayerTextToSpeak,
  getLetterMultiplierChangeTextToSpeak,
  getWordMultiplierChangeTextToSpeak,
  useSpeak,
} from '../../services/speechSynthesis';

export default function useSpeechEffect(
  settings: Settings,
  state: ScrabbleScorerState
) {
  const { t } = useTranslation();
  const { speak } = useSpeak();

  useEffect(() => {
    if (!settings.isSoundEnabled) return;

    if (!state.lastAction) return;

    const { type, payload } = state.lastAction;
    let score = 0;
    let multiplier: Multiplier = null;
    switch (type) {
      case ScrabbleActionType.WordChange:
        const { word } = payload;
        score = state.currentlyPlayedWord.score;
        if (word !== '') {
          const phrase = t('speechSynthesis.wordChange', { word, score });
          speak(phrase);
        }
        break;
      case ScrabbleActionType.WordMultiplierChange:
        multiplier = payload.multiplier;
        score = state.currentlyPlayedWord.score;
        const speakText = getWordMultiplierChangeTextToSpeak(
          multiplier,
          score,
          t
        );
        speak(speakText);
        break;
      case ScrabbleActionType.LetterMultiplierChange:
        const letterIndex = payload.letterIndex;
        multiplier = payload.multiplier;
        const letter = state.currentlyPlayedWord.letters[letterIndex];
        score = state.currentlyPlayedWord.score;
        const text = getLetterMultiplierChangeTextToSpeak(
          letter.letter,
          multiplier,
          score,
          t
        );
        speak(text);
        break;
      case ScrabbleActionType.AssignScoreToPlayer:
        const { playerNumber } = payload;
        const player = state.players[playerNumber - 1];
        const playedWord = player.playedWords[player.playedWords.length - 1];
        if (playedWord) {
          score = playedWord.score;
          const { name } = player;
          if (score !== 0) {
            const speakText = getAssignScoreToPlayerTextToSpeak(t, score, name);
            speak(speakText);
          }
        }

        break;
    }
    // TODO: CHECK!!!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // speak,
    state.currentlyPlayedWord.letters,
    state.currentlyPlayedWord.score,
    state.lastAction,
    state.players,
  ]);
}
