import React from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Player } from '../models';

interface PlayerSettingsProps {
  player: Player;
  onPlayerChange: (player: Player) => void; // could be more granular (name, displayName)
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

function PlayerSettings({
  player,
  onPlayerChange: onPlayersChange,
}: PlayerSettingsProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const handlePlayerDisplayNameChange = (displayName: string) =>
    onPlayersChange({
      ...player,
      displayName,
    });
  const handlePlayerNameChange = (name: string) =>
    onPlayersChange({
      ...player,
      name,
      displayName: name.substring(0, 2),
    });

  return (
    <div className={classes.root}>
      <Typography variant="h6" align="center">
        {player.name}
      </Typography>
      <form noValidate autoComplete="off">
        <div className={classes.form}>
          <TextField
            id="player-name"
            label={t('playerSettings.name')}
            value={player.name}
            inputProps={{ maxLength: 15 }}
            onChange={(event) => handlePlayerNameChange(event.target.value)}
            onFocus={(event) => event.target.select()}
          />
          <TextField
            id="player-display-name"
            label={t('playerSettings.displayName')}
            value={player.displayName}
            inputProps={{ maxLength: 2 }}
            onChange={(event) =>
              handlePlayerDisplayNameChange(event.target.value)
            }
            onFocus={(event) => event.target.select()}
          />
        </div>
      </form>
    </div>
    // <div>Player {player.displayName}</div>
  );
}

export default PlayerSettings;
