import i18n from 'i18next';
import { Device } from '@capacitor/device';
import { initialSettings } from './services/scrabble/scrabbleStateUtil';
import { initReactI18next } from 'react-i18next';

import { Language } from './languages/models';
import * as localStorageService from './services/localStorage';

import en from './languages/locales/en.json';
import es from './languages/locales/es.json';
import fr from './languages/locales/fr.json';
import it from './languages/locales/it.json';
import nl from './languages/locales/nl.json';
import pt from './languages/locales/pt.json';
const resources = {
  en,
  es,
  fr,
  it,
  nl,
  pt,
};

const DEFAULT_LANGUAGE = Language.en;

async function getUserLanguage() {
  let language;
  const settings = localStorageService.loadSettings() || {};
  if (settings && settings.language) {
    language = settings.language;
  } else {
    language = (await Device.getLanguageTag()).value;
    if (!language) {
      language = navigator.language;
    }
    // TODO XXX!!! Using only the first part initially
    language = (language || '').split('-')[0];
    // TODO: Should this be here? this was put here quickly to fix the
    // issue of the language reverting to english after the first open
    // of the app
    localStorageService.saveSettings({
      ...initialSettings,
      ...settings,
      language,
    });
  }
  language = language || DEFAULT_LANGUAGE;
  return language;
}

const detect = async (callback: (locale: string) => void) => {
  const locale = await getUserLanguage();
  callback(locale);
};

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect,
};

i18n
  // @ts-ignore
  .use(languageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: DEFAULT_LANGUAGE,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export const changeLanguage = async (language: string) =>
  await i18n.changeLanguage(language);

export const getCurrentLanguage = () => {
  let language = i18n.language;
  // This doesn't seem necessary but just to be safe
  if (!(Object as any).values(Language).includes(language)) {
    language = DEFAULT_LANGUAGE;
  }
  return language as Language;
};

export default i18n;
