import useMediaQuery from '@material-ui/core/useMediaQuery';

import { mainTheme } from '../../theme';

export default function useMainContainerMaxWidth() {
  let mainContainerMaxWidth: 'sm' | 'lg' = 'lg';
  const isScreenLargerThanMedium = useMediaQuery(
    mainTheme.breakpoints.up('md')
  );
  if (isScreenLargerThanMedium) {
    mainContainerMaxWidth = 'sm';
  }
  return mainContainerMaxWidth;
}
