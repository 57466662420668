import React, { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Container } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  AppBar,
  LetterScorerList,
  PlayersBar,
  WordInputControl,
  WordScore,
} from '../components';
import {
  useAdmobSetupEffect,
  useGoogleAnalyticsEffect,
  useSaveSettingsToLocalStorageEffect,
  useSaveStateToLocalStorageEffect,
  useSpeechEffect,
} from '../hooks/effects';
import { useMainContainerMaxWidth } from '../hooks/ui';
import { AboutModal, RemoveAdsModal, WordListeningErrorModal } from './modals';
import {
  Multiplier,
  Player,
  ScrabbleScorerState,
  ScreenState,
  Settings,
} from '../models';
import {
  initialSettings,
  initialState,
  joinPlayedLetters,
} from '../services/scrabble/scrabbleStateUtil';
import {
  dispatchAddPlayerAction,
  dispatchAssignScoreToPlayerAction,
  dispatchDeletePlayerWordAction,
  dispatchLetterMultiplierChangeAction,
  dispatchNewGameAction,
  dispatchPlayersChangeAction,
  dispatchWordChangeAction,
  dispatchWordMultiplierChangeAction,
  reducer,
} from '../services/scrabble/scrabbleLogic';
import { changeLanguage } from '../i18n';
import { gaEvent } from '../util/ga';
import { mainTheme, whiteTheme } from '../theme';
import * as languagesConfig from '../languages';
import * as localStorageService from '../services/localStorage';
import config from '../config';
import PlayersSettings from './PlayersSettings';
import SettingsScreen from './Settings';

const useLetterScorerListBoxStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: mainTheme.spacing(1),
    // make room for the AdMob banner bottom ad (if any)
    marginBottom: `${
      config.app.mobile.adMob.bannerAdBottomSpacePx + mainTheme.spacing(1)
    }px`,
    width: '70%',
    margin: '0 auto',
  },
}));

const loadStateFromLocalStorageOrDefault = (
  playerDefaultName: string,
  playerDefaultDisplayNameInitial: string
): ScrabbleScorerState =>
  localStorageService.loadState() ||
  initialState(playerDefaultName, playerDefaultDisplayNameInitial);

const loadSettingsFromLocalStorageOrDefault = (): Settings =>
  localStorageService.loadSettings() || initialSettings;

const { MAIN_SCREEN, PLAYER_SETTINGS, SETTINGS } = ScreenState;

function ScrabbleScorer() {
  const { t } = useTranslation();
  const playerDefaultName = t('player.defaultName');
  const playerDefaultDisplayNameInitial = t('player.defaultDisplayNameInitial');

  const [state, dispatch] = useReducer<typeof reducer>(
    reducer,
    loadStateFromLocalStorageOrDefault(
      playerDefaultName,
      playerDefaultDisplayNameInitial
    )
  );
  const [settings, setSettings] = useState<Settings>(
    loadSettingsFromLocalStorageOrDefault
  );
  const [screenState, setScreenState] = useState<ScreenState>(MAIN_SCREEN);

  const [wordListeningError, setWordListeningError] = useState<Error>();
  const [isAboutModalOpen, setAboutModalOpen] = useState(false);
  const [isRemoveAdsModalOpen, setRemoveAdsModalOpen] = useState(false);

  const letterValues = languagesConfig.getLanguageConfig().letters;

  useAdmobSetupEffect();
  useSaveStateToLocalStorageEffect(state);
  useSaveSettingsToLocalStorageEffect(settings);
  useSpeechEffect(settings, state);
  useGoogleAnalyticsEffect(state);

  const mainContainerMaxWidth = useMainContainerMaxWidth();
  const letterScorerListBoxClasses = useLetterScorerListBoxStyles();

  // screen state
  const handleShowPlayerSettings = () => {
    setScreenState(PLAYER_SETTINGS);
    gaEvent('screen_player_settings');
  };
  const handlePlayerSettingsClose = () => setScreenState(MAIN_SCREEN);

  if (process.env.NODE_ENV === 'development') {
    console.debug('👾', 'state', state);
    console.debug('🤖', 'config', JSON.stringify(config));
  }

  // ----------------------------------------------------------------------
  const handleNewGame = () =>
    dispatchNewGameAction(dispatch)(
      initialState(playerDefaultName, playerDefaultDisplayNameInitial)
    );
  const handleWordMultiplierChange = (multiplier: Multiplier) =>
    dispatchWordMultiplierChangeAction(dispatch)(multiplier);
  const handleWordChange = (word: string) =>
    dispatchWordChangeAction(dispatch)(word);
  const handleLetterMultiplierChange = (
    letterIndex: number,
    multiplier: Multiplier
  ) => dispatchLetterMultiplierChangeAction(dispatch)(letterIndex, multiplier);
  const handleAddPlayer = () => dispatchAddPlayerAction(dispatch)();
  const handleAssignScoreToPlayer = (playerNumber: number) =>
    dispatchAssignScoreToPlayerAction(dispatch)(playerNumber);
  const handlePlayersChange = (players: Player[]) =>
    dispatchPlayersChangeAction(dispatch)(players);
  const handleDeletePlayerWord = (
    playerNumber: number,
    playedWordNumber: number
  ) => dispatchDeletePlayerWordAction(dispatch)(playerNumber, playedWordNumber);
  // ----------------------------------------------------------------------
  const handleShowAboutModal = () => setAboutModalOpen(true);
  const handleShowSettingsScreen = () => setScreenState(ScreenState.SETTINGS);
  const handleShowRemoveAdsModal = () => setRemoveAdsModalOpen(true);

  const handleAudioErrorModalClose = () => setWordListeningError(null);
  const handleSettingsCancel = () => setScreenState(ScreenState.MAIN_SCREEN);
  const handleAboutModalClose = () => setAboutModalOpen(false);
  const handleRemoveAdsModalClose = () => setRemoveAdsModalOpen(false);

  const handleWordListeningError = setWordListeningError;

  const handleSettingsChange = async (newSettings: Settings) => {
    if (newSettings.language !== settings.language) {
      // The languages are local resources, this is almost immediate, not using
      // a "loading..." UI state
      await changeLanguage(newSettings.language);
      gaEvent('action_change_language');
    }
    setSettings(newSettings);
    setScreenState(ScreenState.MAIN_SCREEN);
  };
  // ----------------------------------------------------------------------

  const theme = screenState === MAIN_SCREEN ? mainTheme : whiteTheme;

  const {
    currentlyPlayedWord: { letters, multiplier, score },
  } = state;
  const word = joinPlayedLetters(letters);
  const isEmptyWord = word === '';

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <AppBar
          onNewGame={handleNewGame}
          onAbout={handleShowAboutModal}
          onSettings={handleShowSettingsScreen}
          onRemoveAds={handleShowRemoveAdsModal}
        />
        {screenState === PLAYER_SETTINGS && (
          <PlayersSettings
            players={state.players}
            onClose={handlePlayerSettingsClose}
            onPlayersChange={handlePlayersChange}
            onPlayerDeletePlayedWord={handleDeletePlayerWord}
          />
        )}
        {screenState === MAIN_SCREEN && (
          <>
            <Container maxWidth={mainContainerMaxWidth}>
              <WordInputControl
                word={word}
                multiplier={multiplier}
                onMultiplierChange={handleWordMultiplierChange}
                onWordChange={handleWordChange}
                onWordListeningError={handleWordListeningError}
              />
              <Box className={letterScorerListBoxClasses.root}>
                <LetterScorerList
                  playedLetters={letters}
                  letterValues={letterValues}
                  onMultiplierChange={handleLetterMultiplierChange}
                />
                {!!score && <WordScore score={score} />}
              </Box>
            </Container>
            <PlayersBar
              disabledPlayerButtons={isEmptyWord}
              players={state.players}
              onAddPlayer={handleAddPlayer}
              onAssignScoreToPlayer={handleAssignScoreToPlayer}
              onShowPlayerSettings={handleShowPlayerSettings}
              isPlayerScoreBadgeEnabled={settings.isPlayerScoreBadgeEnabled}
            />
          </>
        )}
        {screenState === SETTINGS && (
          <SettingsScreen
            onChange={handleSettingsChange}
            onCancel={handleSettingsCancel}
            value={settings}
          />
        )}
        <AboutModal open={isAboutModalOpen} onClose={handleAboutModalClose} />
        <WordListeningErrorModal
          error={wordListeningError}
          open={!!wordListeningError}
          onClose={handleAudioErrorModalClose}
        />
        <RemoveAdsModal
          open={isRemoveAdsModalOpen}
          onClose={handleRemoveAdsModalClose}
        />
      </CssBaseline>
    </ThemeProvider>
  );
}

export default ScrabbleScorer;
