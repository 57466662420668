import React from 'react';
import { useTranslation } from 'react-i18next';

import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { Player } from '../models';
import { joinPlayedLetters } from '../services/scrabble/scrabbleStateUtil';

interface PlayerWordsProps {
  player: Player;
  onDeletePlayedWord: (playedWordNumber: number) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
}));

function PlayerWords({ player, onDeletePlayedWord }: PlayerWordsProps) {
  const { t } = useTranslation();
  const { playedWords } = player;

  const classes = useStyles();

  const handleDeletePlayedWord = (playedWordNumber: number) =>
    onDeletePlayedWord(playedWordNumber);

  return (
    <div className={classes.root}>
      {playedWords.length === 0 && (
        <Typography variant="body1" gutterBottom align="center">
          {t('playerWords.playerDoesntHaveWords')}
        </Typography>
      )}
      {playedWords.length !== 0 && (
        <>
          <Typography variant="subtitle1" gutterBottom align="center">
            {t('playerWords.title')}
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="Words table" size="small">
              <TableBody>
                {playedWords.map((playedWord, i) => (
                  <TableRow
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${joinPlayedLetters(playedWord.letters)}${i}`}
                  >
                    <TableCell scope="row">
                      {joinPlayedLetters(playedWord.letters)}
                    </TableCell>
                    <TableCell align="right">{playedWord.score}</TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => handleDeletePlayedWord(i)}>
                        <DeleteIcon fontSize="large" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}

export default PlayerWords;
