import { LetterValues } from '../../models';

const letters: LetterValues = {
  A: 1,
  B: 5,
  C: 2,
  D: 5,
  E: 1,
  F: 5,
  G: 8,
  H: 8,
  I: 1,
  L: 3,
  M: 3,
  N: 3,
  O: 1,
  P: 5,
  Q: 10,
  R: 2,
  S: 2,
  T: 2,
  U: 3,
  V: 5,
  Z: 8,
};

export default letters;
