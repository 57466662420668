import { createTheme, ThemeOptions } from '@material-ui/core/styles';

export const ManualThemeColours = {
  DARK_BLUE: '#408DE7',
  DARK_GRAY: '#999999',
  DARKER_BLUE: '#BFE0FC',
  GRAY: '#BFBFBF',
  LIGHT_BLUE: '#E1EEFD',
  LIGHT_GREEN: '#628753',
  LIGHT_RED: '#F1A7AC',
  LIGHT_YELLOW: '#E8C796',
  MAIN_BLUE_DARK: '#173fa8',
  MAIN_BLUE_LIGHT: '#89B9F7',
  MAIN_BLUE_MAIN: '#34A1FF',
  MAIN_GREEN_DARK: '#2f5641',
  MAIN_GREEN_DARKER: '#264936', // darker?
  MAIN_GREEN: '#486D39',
  RED: '#DF424D',
  TILE_YELLOWISH: '#F5CF90',
  WHITE: '#fff',
  YELLOW: '#DDAD65',
};

const {
  MAIN_BLUE_DARK,
  MAIN_BLUE_LIGHT,
  MAIN_BLUE_MAIN,
  MAIN_GREEN_DARK,
  MAIN_GREEN_DARKER,
  MAIN_GREEN,
  WHITE,
} = ManualThemeColours;

const mainThemeValues: ThemeOptions = {
  palette: {
    background: { default: MAIN_GREEN },
    primary: {
      light: MAIN_GREEN,
      main: MAIN_GREEN_DARK,
      dark: MAIN_GREEN_DARKER,
      contrastText: WHITE,
    },
    secondary: {
      light: MAIN_BLUE_LIGHT,
      main: MAIN_BLUE_MAIN,
      dark: MAIN_BLUE_DARK,
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  // Example component overrides
  // overrides: {
  //   MuiTableBody: {
  //     root: {
  //       backgroundColor: ManualThemeColours.LIGHT_GRAY, // lighter than primary.light
  //     },
  //   },
  //   MuiTableCell: {
  //     root: {
  //       borderBottom: '1px solid rgba(38, 73, 54, 1)', // primary.dark
  //     },
  //   },
  //   MuiIconButton: {
  //     root: {
  //       color: 'darkgray'
  //     },
  //   }
  // },
};

const whiteThemeValues: ThemeOptions = {
  ...mainThemeValues,
  palette: {
    ...mainThemeValues.palette,
    background: {
      ...mainThemeValues.palette.background,
      default: '#fff',
    },
  },
};

const mainTheme = createTheme(mainThemeValues);
const whiteTheme = createTheme(whiteThemeValues);

export { mainTheme, whiteTheme };
