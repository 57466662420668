import {
  LetterValues,
  Multiplier,
  PlayedLetter,
  PlayedWord,
  Player,
  ScrabbleScorerState,
} from '../../models';
import {
  getStateWithDeletedPlayerPlayedWord,
  getStateWithNewLetterMultiplier,
  getStateWithNewPlayedWord,
  getStateWithNewPlayer,
  getStateWithNewPlayers,
  getStateWithNewWordMultiplier,
  getStateWithScoreAssignedToPlayer,
} from './scrabbleStateUtil';

const sum = (a: number, b: number) => a + b;

export const calculateWordScore = (
  letters: PlayedLetter[],
  multiplier: Multiplier
): number => {
  const lettersScore = letters.map((letter) => letter.score).reduce(sum, 0);
  return lettersScore * multiplier;
};

export const calculateWordsScore = (playedWords: PlayedWord[]): number =>
  playedWords.reduce(
    (totalScore, playedWord) => totalScore + playedWord.score,
    0
  );

export const calculateLetterScore = (
  letter: string,
  multiplier: Multiplier,
  letterValues: LetterValues
): number => letterValues[letter] * multiplier;

export enum ScrabbleActionType {
  NewGame = 'NewGame',
  WordMultiplierChange = 'WordMultiplierChange',
  WordChange = 'WordChange',
  LetterMultiplierChange = 'LetterMultiplierChange',
  AddPlayer = 'AddPlayer',
  AssignScoreToPlayer = 'AssignScoreToPlayer',
  PlayersChange = 'PlayersChange',
  DeletePlayerWord = 'DeletePlayerWord',
}

type NewGameAction = {
  type: ScrabbleActionType.NewGame;
  payload: {
    initialState: ScrabbleScorerState;
  };
};

export type WordMultiplierChangeAction = {
  type: ScrabbleActionType.WordMultiplierChange;
  payload: {
    multiplier: Multiplier;
  };
};

export type WordChangeAction = {
  type: ScrabbleActionType.WordChange;
  payload: {
    word: string;
  };
};

export type LetterMultiplierChangeAction = {
  type: ScrabbleActionType.LetterMultiplierChange;
  payload: {
    letterIndex: number;
    multiplier: Multiplier;
  };
};

export type AddPlayerAction = {
  type: ScrabbleActionType.AddPlayer;
  payload?: null;
};

export type AssignScoreToPlayerAction = {
  type: ScrabbleActionType.AssignScoreToPlayer;
  payload: {
    playerNumber: number;
  };
};

export type PlayersChangeAction = {
  type: ScrabbleActionType.PlayersChange;
  payload: {
    players: Player[];
  };
};

export type DeletePlayerWordAction = {
  type: ScrabbleActionType.DeletePlayerWord;
  payload: {
    playerNumber: number;
    playedWordNumber: number;
  };
};

export type ScrabbleAction =
  | NewGameAction
  | WordMultiplierChangeAction
  | WordChangeAction
  | LetterMultiplierChangeAction
  | AddPlayerAction
  | AssignScoreToPlayerAction
  | PlayersChangeAction
  | DeletePlayerWordAction;

export function reducer(
  state: ScrabbleScorerState,
  action: ScrabbleAction
): ScrabbleScorerState {
  let resultState: ScrabbleScorerState = null;
  const { type, payload } = action;

  switch (type) {
    case ScrabbleActionType.NewGame: {
      resultState = payload.initialState;
      break;
    }
    case ScrabbleActionType.WordMultiplierChange: {
      resultState = getStateWithNewWordMultiplier(payload.multiplier)(state);
      break;
    }
    case ScrabbleActionType.WordChange: {
      resultState = getStateWithNewPlayedWord(payload.word)(state);
      break;
    }
    case ScrabbleActionType.LetterMultiplierChange: {
      resultState = getStateWithNewLetterMultiplier(
        payload.letterIndex,
        payload.multiplier
      )(state);
      break;
    }
    case ScrabbleActionType.AddPlayer: {
      resultState = getStateWithNewPlayer(state);
      break;
    }
    case ScrabbleActionType.AssignScoreToPlayer: {
      resultState = getStateWithScoreAssignedToPlayer(payload.playerNumber)(
        state
      );
      break;
    }
    case ScrabbleActionType.PlayersChange: {
      resultState = getStateWithNewPlayers(payload.players)(state);
      break;
    }
    case ScrabbleActionType.DeletePlayerWord: {
      resultState = getStateWithDeletedPlayerPlayedWord(
        payload.playerNumber,
        payload.playedWordNumber
      )(state);
      break;
    }
    default:
      throw new Error(`👾 Unknown action type: ${type}`);
  }

  return {
    ...resultState,
    lastAction: action,
  };
}

type DispatchFunction = (value: ScrabbleAction) => void;

// Action creators
export const dispatchNewGameAction =
  (dispatch: DispatchFunction) => (initialState: ScrabbleScorerState) =>
    dispatch({
      type: ScrabbleActionType.NewGame,
      payload: { initialState },
    });

export const dispatchWordMultiplierChangeAction =
  (dispatch: DispatchFunction) => (multiplier: Multiplier) =>
    dispatch({
      type: ScrabbleActionType.WordMultiplierChange,
      payload: { multiplier },
    });

export const dispatchWordChangeAction =
  (dispatch: DispatchFunction) => (word: string) =>
    dispatch({ type: ScrabbleActionType.WordChange, payload: { word } });

export const dispatchLetterMultiplierChangeAction =
  (dispatch: DispatchFunction) =>
  (letterIndex: number, multiplier: Multiplier) =>
    dispatch({
      type: ScrabbleActionType.LetterMultiplierChange,
      payload: { letterIndex, multiplier },
    });

export const dispatchAddPlayerAction = (dispatch: DispatchFunction) => () =>
  dispatch({ type: ScrabbleActionType.AddPlayer });

export const dispatchAssignScoreToPlayerAction =
  (dispatch: DispatchFunction) => (playerNumber: number) =>
    dispatch({
      type: ScrabbleActionType.AssignScoreToPlayer,
      payload: { playerNumber },
    });

export const dispatchPlayersChangeAction =
  (dispatch: DispatchFunction) => (players: Player[]) =>
    dispatch({
      type: ScrabbleActionType.PlayersChange,
      payload: { players },
    });

export const dispatchDeletePlayerWordAction =
  (dispatch: DispatchFunction) =>
  (playerNumber: number, playedWordNumber: number) =>
    dispatch({
      type: ScrabbleActionType.DeletePlayerWord,
      payload: { playerNumber, playedWordNumber },
    });
