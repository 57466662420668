import { useEffect } from 'react';
import { ScrabbleScorerState } from '../../models';
import { ScrabbleActionType } from '../../services/scrabble/scrabbleLogic';
import { gaEvent } from '../../util/ga';

export default function useGoogleAnalyticsEffect(state: ScrabbleScorerState) {
  useEffect(() => {
    if (!state.lastAction) return;

    const { type } = state.lastAction;
    switch (type) {
      case ScrabbleActionType.AddPlayer:
        gaEvent('action_add_player');
        break;
    }
  }, [state.lastAction]);
}
