import {
  AdMob,
  BannerAdOptions,
  BannerAdPluginEvents,
  BannerAdPosition,
  BannerAdSize,
} from '@capacitor-community/admob';

import { gaErrorEvent } from '../util/ga';
import config from '../config';

export async function initialize(): Promise<void> {
  if (!config.app.isMobileAdsEnabled) return;

  try {
    await AdMob.initialize({
      // this is only for iOS(>14), default is true
      // requestTrackingAuthorization: true,
      // if set to true, the devices below will be registered to receive test production-like ads
      initializeForTesting: true,
      // to use production-like ads on your specified devices (safe to click) if initializeForTesting is true
      testingDevices: config.app.mobile.adMob.testDeviceIds,
    });
  } catch (error) {
    gaErrorEvent({ type: 'Ads:initialize', error });
    console.error('☠️', 'Could not initialize adMob', error);
  }
}

// export async function interstitial(): Promise<void> {
//   if (!config.app.isMobileAdsEnabled) return;

//   try {
//     AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info: AdLoadInfo) =>
//       console.debug('👾', '[adMob]interstitial ad load', info)
//     );

//     const options: AdOptions = {
//       adId: config.app.mobile.adMob.interstitialAdId,
//       isTesting: true,
//       // Non-Personalized Ads
//       // npa: true
//     };

//     const prepareResult = await AdMob.prepareInterstitial(options);
//     console.debug('👾', '[adMob]interstitial ad prepare result', prepareResult);
//     const showResult = await AdMob.showInterstitial();
//     console.debug('👾', '[adMob]interstitial ad show result', showResult);
//   } catch (error) {
//     gaErrorEvent({ type: 'Ads:Interstitial', error });
//     console.error('☠️', 'Could not show interstitial ad', error);
//   }
// }

export async function banner(): Promise<void> {
  if (!config.app.isMobileAdsEnabled) return;

  try {
    AdMob.addListener(BannerAdPluginEvents.Loaded, () =>
      console.debug('👾', '[adMob]banner ad loaded')
    );
    AdMob.addListener(BannerAdPluginEvents.FailedToLoad, (event) =>
      console.debug('👾', '[adMob]banner ad failed to load', event)
    );

    const options: BannerAdOptions = {
      adId: config.app.mobile.adMob.bannerAdId,
      adSize: BannerAdSize.BANNER,
      position: BannerAdPosition.BOTTOM_CENTER,
      // margin: 0,
      // isTesting: true,
      // Non-Personalized Ads
      // npa: true
    };
    return await AdMob.showBanner(options);
  } catch (error) {
    gaErrorEvent({ type: 'Ads:banner', error });
    console.error('☠️', 'Could not show banner ad', error);
  }
}

export async function setupAdmob() {
  await initialize();
  await banner();
}
