import * as React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  letterScore: {
    fontFamily: 'Roboto Mono',
    fontSize: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface LetterScorerTotalProps {
  score: number;
}

export default function LetterScorerTotal({ score }: LetterScorerTotalProps) {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={4}>
        Total
      </Grid>
      <Grid item xs={4}>
        Y
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.letterScore}>{score}</Paper>
      </Grid>
    </>
  );
}
