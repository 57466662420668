import { Device } from '@capacitor/device';

let isGaEnabled = false;

const NO_GA_DEVICE_UUIDS = [
  '5b03b137d1e0d76f', // Pixel 5 emulator
  '49e8ad1cbdd90308', // Pixel 3
  '401ea3e05d6a1066', // Oppo
  '6cc77f5c-9fb6-4cda-80c3-d45a83b70044', // Web
];

try {
  async function initialiseGa() {
    let deviceId = '';
    let isDevelopmentDevice = false;
    let _IS_DEV_ = process.env.NODE_ENV === 'development';
    let _IS_TEST_ = process.env.NODE_ENV === 'test';

    try {
      deviceId = (await Device.getId()).identifier;
      isDevelopmentDevice = NO_GA_DEVICE_UUIDS.includes(deviceId);
    } catch (err) {
      console.error(
        '☠️',
        'Error looking for GA initialisation conditions',
        err
      );
    }

    if (_IS_DEV_ || _IS_TEST_ || isDevelopmentDevice) {
      if (!_IS_TEST_)
        console.log(
          '👾',
          `[GA]Not initialising on NODE_ENV=${process.env.NODE_ENV} or known device (${deviceId})`
        );
    } else {
      console.log('👾', '[GA]Initialising...');
      // https://stackoverflow.com/a/47943868/1084192
      isGaEnabled = true;
      ga('config', 'G-VWD025XYCQ');
    }
  }
  initialiseGa().catch((err) =>
    console.error('☠️', 'Could not initialize GA', err)
  );
} catch (err) {
  console.error('☠️', 'Could not initialize GA', err);
}

function ga(...params: any[]) {
  if (isGaEnabled) {
    try {
      // @ts-ignore
      gtag(...params);
    } catch (err) {
      console.error('☠️', 'Error calling GA', err);
    }
  }
}

type ErrorEvent = {
  type: string;
  error: Error;
};

export function gaErrorEvent({ type, error }: ErrorEvent) {
  if (isGaEnabled) {
    ga('event', 'error', {
      type,
      error: {
        name: error?.name,
        message: error?.message,
      },
    });
  }
}

export function gaEvent(type: string, event?: any) {
  if (isGaEnabled) ga('event', type, event);
}
