import { default as enLetters } from './letters/letters.en';
import { default as esLetters } from './letters/letters.es';
import { default as frLetters } from './letters/letters.fr';
import { default as itLetters } from './letters/letters.it';
import { default as nlLetters } from './letters/letters.nl';
import { default as ptLetters } from './letters/letters.pt';

import { getCurrentLanguage } from '../i18n';
import { Language, LanguageConfigs } from './models';
import { tokeniseBasic, tokeniseFrench, tokeniseSpanish } from './tokenise';

export const DEFAULT_LANGUAGE = Language.en;

const languageConfigs: LanguageConfigs = {
  en: {
    letters: enLetters,
    inputCleaningRegex: /([^a-z]+)/gi,
    tokenisationFunction: tokeniseBasic,
  },
  es: {
    letters: esLetters,
    inputCleaningRegex: /([^a-zñáéíóú]+)/gi,
    tokenisationFunction: tokeniseSpanish,
  },
  fr: {
    letters: frLetters,
    inputCleaningRegex: /([^a-zçàéèùâêîôûëïü]+)/gi,
    tokenisationFunction: tokeniseFrench,
  },
  it: {
    letters: itLetters,
    inputCleaningRegex: /([^a-zóéàèìòù]+)/gi,
    tokenisationFunction: tokeniseBasic,
  },
  nl: {
    letters: nlLetters,
    inputCleaningRegex: /([^a-záéíóúäëïöü]+)/gi,
    tokenisationFunction: tokeniseBasic,
  },
  pt: {
    letters: ptLetters,
    inputCleaningRegex: /([^a-zçâãàêèìôõòüù]+)/gi,
    tokenisationFunction: tokeniseBasic,
  },
};

// These are defined in ../i18n because otherwise the tests will fail because of Suspense 🙄
// export const resources = {
//   en,
//   es,
//   fr,
//   ...
// };

// Remember values-xx in src/fraka-scrabble-score/android/app/src/main/res

export const supportedLanguages = [
  { value: 'en', name: 'English' },
  { value: 'es', name: 'Español' },
  { value: 'pt', name: 'Português' },
  { value: 'fr', name: 'Française' },
  { value: 'nl', name: 'Nederlands' },
  { value: 'it', name: 'Italiano' },
];

// TODO: Web TTS still WIP in terms of voice selection
export const preferredVoicesWeb = {
  en: { lang: ['en-US', 'en_US', 'en'], voice: ['Google US English'] },
  es: { lang: ['es-US', 'es_US', 'es'], voice: ['Albert'] },
  fr: { lang: ['fr'], voice: ['TODO'] },
  pt: { lang: ['pt'], voice: ['TODO'] },
  nl: { lang: ['nl'], voice: ['TODO'] },
  it: { lang: ['it'], voice: ['TODO'] },
};

export const getLanguageConfig = () => languageConfigs[getCurrentLanguage()];

export const getLettersValues = () => getLanguageConfig().letters;
