import * as React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import VeneerBirchTextureImage from '../resources/images/veneer-birch-texture.png';
import { ManualThemeColours } from '../theme';

const { TILE_YELLOWISH } = ManualThemeColours;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0.5mm',
    backgroundColor: TILE_YELLOWISH,
    backgroundImage: `url(${VeneerBirchTextureImage})`,
    position: 'relative',
    width: theme.spacing(9),
    height: theme.spacing(9),
    boxSizing: 'border-box',
    boxShadow: '0 1.125mm 0.75mm -0.5mm rgba(0, 0, 0, 0.4)',
    borderStyle: 'solid',
    borderTopWidth: '0.75mm',
    borderLeftWidth: '1px',
    borderRightWidth: '1px',
    borderBottomWidth: '1.125mm',
    borderTopColor: 'rgba(255, 255, 255, 0.45)',
    borderLeftColor: 'rgba(255, 255, 255, 0.25)',
    borderRightColor: 'rgba(0, 0, 0, 0.15)',
    borderBottomColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '0.75mm',
    '&[data-letter]::before': {
      content: 'attr(data-letter)',
      textTransform: 'uppercase',
      fontFamily: 'Oswald, sans-serif',
      fontSize: theme.spacing(6.25),
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'rgba(0, 0, 0, 0.6)',
      textShadow:
        '4px 4px 6px #f5cf90, 0 0 0 rgba(0, 0, 0, 0.7), 1px 2px 1px rgba(255, 255, 255, 0.5)',
    },
    '&[data-value]::after': {
      content: 'attr(data-value)',
      color: 'rgba(0, 0, 0, 0.7)',
      fontFamily: 'sans-serif',
      fontSize: '4.275mm',
      position: 'absolute',
      right: '12.5%',
      bottom: '12.5%',
      transform: 'translate(50%, 50%)',
    },
  },
}));

interface LetterTileProps {
  letter: string;
  value: number;
}

export default function LetterTile({ letter, value }: LetterTileProps) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      data-letter={letter}
      data-value={value}
      data-testid="letter-tile"
    />
  );
}
