import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import config, { AppFlavour } from '../../config';

type AboutModalProps = {
  open: boolean;
  onClose: MouseEventHandler | undefined;
};

export default function AboutModal({ open, onClose }: AboutModalProps) {
  const { t } = useTranslation();

  const { privacyPolicyUrl } = config.app;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="about-dialog-title"
      aria-describedby="about-dialog-description"
    >
      <DialogTitle id="about-dialog-title">{t('about.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="about-dialog-description">
          {t('about.versionSubtitle')} {config.app.version} (
          {config.app.flavour === AppFlavour.Free
            ? t('about.freeVersionDescription')
            : t('about.proVersionDescription')}
          )
        </DialogContentText>
        <DialogContentText id="about-dialog-description">
          <a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
            {t('about.privacyPolicy')}
          </a>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          {t('about.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
