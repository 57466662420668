import { ScrabbleAction } from '../services/scrabble/scrabbleLogic';

enum Multiplier {
  NONE = 1,
  X2 = 2,
  X3 = 3,
}

enum ScreenState {
  MAIN_SCREEN = 'MAIN_SCREEN',
  PLAYER_SETTINGS = 'PLAYER_SETTINGS',
  SETTINGS = 'SETTINGS',
}

enum MultiplierVariant {
  WORD = 'WORD',
  LETTER = 'LETTER',
}

interface ScrabbleScorerState {
  currentlyPlayedWord: PlayedWord;
  players: Player[];
  screenState: ScreenState;
  lastAction: ScrabbleAction;
}

interface Player {
  currentScore: number;
  displayName: string;
  hasPlayedInCurrentTurn: boolean;
  name: string;
  playedWords: PlayedWord[];
}

interface PlayedLetter {
  letter: string;
  multiplier: Multiplier;
  score: number;
}

interface PlayedWord {
  letters: PlayedLetter[];
  multiplier: Multiplier;
  score: number;
}

interface LetterValues {
  [letter: string]: number;
}

export {
  LetterValues,
  Multiplier,
  MultiplierVariant,
  PlayedLetter,
  PlayedWord,
  Player,
  ScrabbleScorerState,
  ScreenState,
};

export type Settings = {
  isSoundEnabled?: boolean;
  isPlayerScoreBadgeEnabled?: boolean;
  language?: string; // es, en, pt
};
