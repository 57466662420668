import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type WordListeningErrorModalProps = {
  open: boolean;
  onClose: MouseEventHandler | undefined;
  error?: Error;
};

export default function WordListeningErrorModal({
  error,
  open,
  onClose,
}: WordListeningErrorModalProps) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="audio-error-dialog-title"
      aria-describedby="audio-error-dialog-description"
    >
      <DialogTitle id="audio-error-dialog-title">
        {t('speechRecognition.errorTitle')}
      </DialogTitle>
      <DialogContent>
        {error && (
          <DialogContentText id="about-dialog-description">
            {error.message}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          {t('about.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
