import * as React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';

import MultiplierControl from './MultiplierControl';
import LetterTile from './LetterTile';

import {
  LetterValues,
  Multiplier,
  MultiplierVariant,
  PlayedLetter,
} from '../models';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  letterScore: {
    fontFamily: 'Roboto Mono',
    fontSize: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface LetterScorerProps {
  playedLetter: PlayedLetter;
  letterValues: LetterValues;
  disabledMultiplierControls?: boolean;
  onMultiplierChange: (multiplier: Multiplier) => void;
}

export default function LetterScorer({
  playedLetter,
  letterValues,
  disabledMultiplierControls = false,
  onMultiplierChange,
}: LetterScorerProps) {
  const classes = useStyles();

  const { letter, multiplier, score } = playedLetter;

  return (
    <>
      <Grid item xs={4}>
        <LetterTile
          letter={letter}
          value={letterValues[letter.toLocaleUpperCase()] || 0}
        />
      </Grid>
      <Grid item xs={4}>
        <MultiplierControl
          value={multiplier}
          variant={MultiplierVariant.LETTER}
          onMultiplierChange={onMultiplierChange}
          disabled={disabledMultiplierControls}
        />
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.letterScore}>{score}</Paper>
      </Grid>
    </>
  );
}

LetterScorer.defaultProps = {
  disabledMultiplierControls: false,
};
