import { t } from 'i18next';

import { calculateLetterScore, calculateWordScore } from './scrabbleLogic';
import { Multiplier, PlayedLetter, PlayedWord, Player } from '../../models';
import * as languagesConfig from '../../languages';

// Create PlayedWord keeping previous letters. Early optimisation?
const createPlayedWord = (
  word: string,
  previousPlayedLetters: PlayedLetter[]
): PlayedWord => {
  const letterValues = languagesConfig.getLanguageConfig().letters;

  const multiplier = Multiplier.NONE;
  const letters: PlayedLetter[] = [];
  let score = 0;

  const tokenisationFunction =
    languagesConfig.getLanguageConfig().tokenisationFunction;

  const letterTokens = tokenisationFunction(word);
  letterTokens.forEach((letter: string, i: number) => {
    if (
      previousPlayedLetters[i] &&
      letter === previousPlayedLetters[i].letter
    ) {
      // minor optimisation, reuse previous calculations if letter doesn't change
      letters[i] = previousPlayedLetters[i];
    } else {
      const multiplier = Multiplier.NONE;
      const score = calculateLetterScore(letter, multiplier, letterValues);
      letters[i] = {
        letter,
        multiplier,
        score,
      };
    }
  });

  score = calculateWordScore(letters, Multiplier.NONE);

  return {
    letters,
    score,
    multiplier,
  };
};

const createPlayedWordFromPreviousWithNewLetterMultiplier = (
  previousPlayedWord: PlayedWord,
  updatedLetterMultiplierIndex: number,
  multiplier: Multiplier
): PlayedWord => {
  const letterValues = languagesConfig.getLanguageConfig().letters;

  const i = updatedLetterMultiplierIndex;
  const { letter } = previousPlayedWord.letters[i];

  const newLetters = [...previousPlayedWord.letters];
  newLetters[i] = {
    letter,
    multiplier,
    score: calculateLetterScore(letter, multiplier, letterValues),
  };

  const newPlayedWord: PlayedWord = {
    letters: newLetters,
    multiplier: previousPlayedWord.multiplier,
    score: calculateWordScore(newLetters, previousPlayedWord.multiplier),
  };

  return newPlayedWord;
};

const createPlayedWordFromPreviousWithNewMultiplier = (
  newMultiplier: Multiplier,
  previousPlayedWord: PlayedWord
): PlayedWord => ({
  letters: previousPlayedWord.letters,
  multiplier: newMultiplier,
  score: calculateWordScore(previousPlayedWord.letters, newMultiplier),
});

const createPlayer = (playerNumber: number): Player => ({
  currentScore: 0,
  displayName: `J${playerNumber}`,
  hasPlayedInCurrentTurn: false,
  name: `${t('player.defaultName')} ${playerNumber}`,
  playedWords: [],
});

export {
  createPlayer,
  createPlayedWord,
  createPlayedWordFromPreviousWithNewMultiplier,
  createPlayedWordFromPreviousWithNewLetterMultiplier,
};
