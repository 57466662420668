import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';

import { Multiplier, MultiplierVariant } from '../models';
import * as languagesConfig from '../languages';
import MultiplierControl from './MultiplierControl';
import WordSpeechInputControl from './WordSpeechInputControl';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    color: 'white',
  },
}));

interface WordInputControlProps {
  onWordChange: (word: string) => void;
  onWordListeningError: (error: Error) => void;
  onMultiplierChange: (multiplier: Multiplier) => void;
  multiplier: Multiplier;
  word: string;
}

type Event = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

/*
 * This component is uncontrolled. Previously, it was controlled with the state (`word`) in the
 * parent, but had to changed given the debounce for speech synthesis. It is still receiving the
 * `word` from the parent state, but it only uses it to clear the word when the parent want to
 * clears it.
 */
function WordInputControl({
  onWordChange,
  onWordListeningError,
  onMultiplierChange,
  word = '',
  multiplier = Multiplier.NONE,
}: WordInputControlProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const inputTextFieldRef = useRef<HTMLInputElement>(null);

  const handleMultiplierChange = (value: Multiplier) =>
    onMultiplierChange(value);

  const handleWordChange = (newWord: string) => {
    const inputCleaningRegex =
      languagesConfig.getLanguageConfig().inputCleaningRegex;
    const cleanWord = newWord.toUpperCase().replace(inputCleaningRegex, '');
    onWordChange(cleanWord);
  };
  const handleWordChangeEvent = (event: Event) => {
    const { value } = event.target;
    handleWordChange(value);
  };
  const handleWordListeningError = onWordListeningError;

  const debouncedHandleChange = debounce(handleWordChangeEvent, 800);

  const isEmptyWord = word === '';
  if (isEmptyWord && inputTextFieldRef.current) {
    inputTextFieldRef.current.value = '';
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={(e) => e.preventDefault()}
    >
      <TextField
        data-testid="word-input-text-control"
        label={t('wordInputPlaceholder')}
        onChange={debouncedHandleChange}
        inputRef={inputTextFieldRef}
        InputProps={{
          className: classes.input,
          style: { textTransform: 'uppercase' },
        }}
      />
      {/* // TODO: is available */}
      {/* {SpeechRecognition.isAvailable() && ( */}
      <WordSpeechInputControl
        onWordSpoken={handleWordChange}
        onListeningError={handleWordListeningError}
      />
      {/* )} */}
      <MultiplierControl
        onMultiplierChange={handleMultiplierChange}
        value={multiplier}
        variant={MultiplierVariant.WORD}
        disabled={isEmptyWord}
      />
    </form>
  );
}

export default WordInputControl;
