import * as React from 'react';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Grid from '@material-ui/core/Grid';
import MuiAvatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FaceIcon from '@material-ui/icons/Face';
import Badge from '@material-ui/core/Badge';
import {
  deepOrange,
  deepPurple,
  teal,
  lightGreen,
} from '@material-ui/core/colors';

import { ManualThemeColours } from '../theme';
import { Player } from '../models';
import config from '../config';

type AssignScoreToPlayerFn = (playerNumber: number) => void;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    // make room for the AdMob banner bottom ad (if any)
    paddingBottom: config.app.mobile.adMob.bannerAdBottomSpacePx,
  },
  // When you render the app bar position fixed, the dimension of the element doesn't impact the rest of the page.
  // Ref: https://material-ui.com/components/app-bar/#fixed-placement
  offset: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
  },
}));

const { GRAY, WHITE } = ManualThemeColours;

const useAvatarStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  avatar1: {
    color: WHITE,
    backgroundColor: deepOrange[500],
  },
  avatar2: {
    color: WHITE,
    backgroundColor: deepPurple[500],
  },
  avatar3: {
    color: WHITE,
    backgroundColor: teal[500],
  },
  avatar4: {
    color: WHITE,
    backgroundColor: lightGreen[500],
  },
  disabled: {
    color: 'black',
    backgroundColor: GRAY,
  },
  badge_hasPlayedInCurrentTurn: {
    border: '3px solid transparent',
    borderRadius: '50%',
  },
  badge_hasNotPlayedInCurrentTurn: {
    border: '3px solid #00C213',
    borderRadius: '50%',
  },
}));

interface AvatarProps {
  player: Player;
  playerNumber: number;
  onAssignScoreToPlayer: AssignScoreToPlayerFn;
  disabled: boolean;
  isPlayerScoreBadgeEnabled: boolean;
}

function PlayerAvatar({
  player,
  playerNumber,
  onAssignScoreToPlayer,
  disabled = false,
  isPlayerScoreBadgeEnabled,
}: AvatarProps) {
  const classes = useAvatarStyles();

  let avatarStylesClasses;
  if (disabled) {
    avatarStylesClasses = classes.disabled;
  } else {
    switch (playerNumber) {
      case 1:
        avatarStylesClasses = classes.avatar1;
        break;
      case 2:
        avatarStylesClasses = classes.avatar2;
        break;
      case 3:
        avatarStylesClasses = classes.avatar3;
        break;
      case 4:
        avatarStylesClasses = classes.avatar4;
        break;
      default:
        avatarStylesClasses = classes.avatar1;
    }
  }

  return (
    <IconButton onClick={() => onAssignScoreToPlayer(playerNumber)}>
      <Badge
        className={
          player.hasPlayedInCurrentTurn
            ? classes.badge_hasPlayedInCurrentTurn
            : classes.badge_hasNotPlayedInCurrentTurn
        }
        badgeContent={player.currentScore}
        color="secondary"
        overlap="circular"
        max={999}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        invisible={!isPlayerScoreBadgeEnabled}
      >
        <MuiAvatar className={`${classes.avatar} ${avatarStylesClasses}`}>
          {player.displayName}
        </MuiAvatar>
      </Badge>
    </IconButton>
  );
}

interface PlayerAvatarListProps {
  players: Player[];
  onAssignScoreToPlayer: AssignScoreToPlayerFn;
  disabledPlayerButtons: boolean;
  isPlayerScoreBadgeEnabled: boolean;
}

function PlayerAvatarList({
  players,
  onAssignScoreToPlayer,
  disabledPlayerButtons,
  isPlayerScoreBadgeEnabled,
}: PlayerAvatarListProps) {
  return (
    <>
      {players.map((player, i) => (
        <PlayerAvatar
          player={player}
          playerNumber={i + 1}
          key={player.displayName}
          onAssignScoreToPlayer={onAssignScoreToPlayer}
          disabled={disabledPlayerButtons}
          isPlayerScoreBadgeEnabled={isPlayerScoreBadgeEnabled}
        />
      ))}
    </>
  );
}

interface PlayersBarProps {
  disabledPlayerButtons: boolean;
  players: Player[];
  onAddPlayer: () => void;
  onAssignScoreToPlayer: AssignScoreToPlayerFn;
  onShowPlayerSettings: () => void;
  isPlayerScoreBadgeEnabled: boolean;
}

export default function PlayersBar({
  disabledPlayerButtons,
  players,
  onAddPlayer,
  onAssignScoreToPlayer,
  onShowPlayerSettings,
  isPlayerScoreBadgeEnabled,
}: PlayersBarProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const AddPlayerIcon = () => (
    <IconButton
      onClick={onAddPlayer}
      aria-label={t('playersBar.ariaAddPlayerButton')}
    >
      <AddCircleOutlineIcon fontSize="large" />
    </IconButton>
  );

  const PlayerSettingsIcon = () => (
    <IconButton onClick={onShowPlayerSettings} aria-label="manage players">
      <FaceIcon fontSize="large" />
    </IconButton>
  );

  const renderAddPlayerButton = () => players.length < 4 && <AddPlayerIcon />;

  const renderPlayerSettingsButton = () =>
    players.length >= 2 && <PlayerSettingsIcon />;

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Grid container justifyContent="center" alignItems="center">
          <PlayerAvatarList
            players={players}
            onAssignScoreToPlayer={onAssignScoreToPlayer}
            disabledPlayerButtons={disabledPlayerButtons}
            isPlayerScoreBadgeEnabled={isPlayerScoreBadgeEnabled}
          />
          {renderAddPlayerButton()}
          {renderPlayerSettingsButton()}
        </Grid>
      </AppBar>
      <div className={classes.offset} />
    </>
  );
}
