import * as React from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { Multiplier, MultiplierVariant } from '../models';
import { ManualThemeColours } from '../theme';

type MultiplierColours = {
  [multiplier in Multiplier]: {
    [variant in MultiplierVariant]: {
      COLOUR_ON: string;
      COLOUR_OFF: string;
    };
  };
};

const { X2, X3, NONE } = Multiplier;
const { WORD, LETTER } = MultiplierVariant;

const {
  YELLOW,
  LIGHT_YELLOW,
  MAIN_BLUE_LIGHT: BLUE,
  LIGHT_BLUE,
  RED,
  LIGHT_RED,
  DARK_BLUE,
  DARKER_BLUE,
  GRAY,
  DARK_GRAY,
} = ManualThemeColours;

const MULTIPLIER_COLOURS: MultiplierColours = {
  [NONE]: null,
  [X2]: {
    [WORD]: {
      COLOUR_ON: LIGHT_YELLOW,
      COLOUR_OFF: YELLOW,
    },
    [LETTER]: {
      COLOUR_ON: LIGHT_BLUE,
      COLOUR_OFF: BLUE,
    },
  },
  [X3]: {
    [WORD]: {
      COLOUR_ON: LIGHT_RED,
      COLOUR_OFF: RED,
    },
    [LETTER]: {
      COLOUR_ON: DARKER_BLUE,
      COLOUR_OFF: DARK_BLUE,
    },
  },
};

const DISABLED_MULTIPLIER_STYLE: { [multiplier in Multiplier]: {} } = {
  [NONE]: null,
  [X2]: {
    backgroundColor: GRAY,
    fontWeight: 'normal',
    border: `1px solid ${GRAY}`,
  },
  [X3]: {
    backgroundColor: DARK_GRAY,
    fontWeight: 'normal',
    border: `1px solid ${DARK_GRAY}`,
  },
};

const generateStyleForMultiplierTile = (
  multiplier: Multiplier,
  variant: MultiplierVariant,
  colours: MultiplierColours,
  isToggled = false,
  isDisabled = false
) => {
  if (isDisabled) return DISABLED_MULTIPLIER_STYLE[multiplier];

  const { COLOUR_OFF: backgroundColourOff } = colours[multiplier][variant];
  let backgroundColor = backgroundColourOff;
  let fontWeight = 'normal';
  let border = '0';
  let color = 'black';

  if (isToggled) {
    backgroundColor = 'grey';
    fontWeight = 'bold';
    border = `1px solid grey`;
  }

  return {
    backgroundColor,
    fontWeight,
    border,
    color,
  };
};

interface MultiplierControlProps {
  onMultiplierChange: (multiplier: Multiplier) => void;
  value: Multiplier;
  variant: MultiplierVariant;
  disabled: boolean;
}

// Controled component
export default function MultiplierControl({
  onMultiplierChange,
  value = Multiplier.NONE,
  variant = MultiplierVariant.WORD,
  disabled = false,
}: MultiplierControlProps) {
  // TODO: resorting to inline styles as for some reason the usual "makeStyles"
  // approach seem to be applying globally for all ToggleButtons.
  const x2Style = generateStyleForMultiplierTile(
    X2,
    variant,
    MULTIPLIER_COLOURS,
    value === X2,
    disabled
  );
  const x3Style = generateStyleForMultiplierTile(
    X3,
    variant,
    MULTIPLIER_COLOURS,
    value === X3,
    disabled
  );

  const handleMultiplierChange = (multiplier: Multiplier) =>
    onMultiplierChange(multiplier);

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(_: any, multiplier: Multiplier) =>
        handleMultiplierChange(multiplier || Multiplier.NONE)
      }
      aria-label="Word or letter multiplier"
      data-testid={`${variant}-multiplier-button-group`}
    >
      <ToggleButton
        value={X2}
        aria-label="by 2 multiplier"
        style={x2Style}
        disabled={disabled}
        data-testid={`x2-${variant}-multiplier-button`}
      >
        x2
      </ToggleButton>
      <ToggleButton
        value={X3}
        aria-label="by 3 multiplier"
        style={x3Style}
        disabled={disabled}
        data-testid={`x3-${variant}-multiplier-button`}
      >
        x3
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
