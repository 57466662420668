import { TextToSpeech } from '@capacitor-community/text-to-speech';
import { TFunction } from 'i18next';

import { getCurrentLanguage } from '../i18n';

// @ts-ignore
import { useSpeechSynthesis } from '../lib/react-speech-kit';
import { gaErrorEvent } from '../util/ga';
import { IS_WEB } from '../util/platform';
import { Multiplier } from '../models';
import { preferredVoicesWeb } from '../languages';

let IS_OPEN_INSTALL_EXECUTED = false;

async function androidOpenInstallOnce() {
  if (IS_OPEN_INSTALL_EXECUTED) {
    try {
      // 🤷🏻‍♀️
      // "Starts the activity from the platform TextToSpeech engine to verify the proper
      // installation and availability of the resource files on the system..."
      // https://developer.android.com/reference/android/speech/tts/TextToSpeech.Engine#ACTION_CHECK_TTS_DATA
      IS_OPEN_INSTALL_EXECUTED = true;
      return await TextToSpeech.openInstall();
    } catch (error) {
      IS_OPEN_INSTALL_EXECUTED = true;
      console.error('☠️', '[TTS]TextToSpeech.openInstall error', error);
      gaErrorEvent({ type: 'TTS:Open install', error });
    }
  }
}

function useSpeechSynthesisVoiceWeb(lang: string) {
  const { voices } = useSpeechSynthesis();
  // console.log('👽', 'lang', lang);
  // console.log('👽', 'voices', voices);
  // const voicesLangs = ['es-US', 'es_US', 'es']; // TODO: CHECK!!! FIX!!!
  // @ts-ignore
  // console.log('👽', 'preferredVoices[lang]', preferredVoices[lang]);
  // @ts-ignore
  const voicesLangs = preferredVoicesWeb[lang].lang;
  // @ts-ignore
  const voicesVoice = preferredVoicesWeb[lang].voice;
  // const voicesLangs = [language];
  const matchingLanguageVoices = voices.filter(
    (voice: { lang: string }) =>
      // voice.lang.startsWith(language)
      voicesLangs.includes(voice.lang)
    // voice.voiceURI === 'Paulina' && voice.lang === 'es-MX'
  );
  let voice = null;
  // @ts-ignore
  if (preferredVoicesWeb[lang].voice) {
    voice = matchingLanguageVoices.find((voice: { voiceURI: string }) =>
      voicesVoice.includes(voice.voiceURI)
    );
  }
  if (!voice) {
    voice = matchingLanguageVoices[0];
  }
  // console.log('👽', 'matchingLanguageVoices', matchingLanguageVoices);

  return voice;
}

export function useSpeakWeb(language: string) {
  const { speak } = useSpeechSynthesis();
  const voice = useSpeechSynthesisVoiceWeb(language);
  const speakFn = (text: string) =>
    speak({
      text,
      // https://jsbin.com/ginanegoqu/edit?js,output
      voice,
    });
  return speakFn;
}

export function useSpeak() {
  let speakFn: Function = null;
  const language = getCurrentLanguage();
  const speakWeb = useSpeakWeb(language);

  if (IS_WEB) {
    speakFn = speakWeb;
  } else {
    const speakMobile = async (text: string) => {
      //const supportedVoices = await TextToSpeech.getSupportedVoices();
      // https://docs.google.com/spreadsheets/d/1-G5z5MNAQEn6CjWITq8UfqADbDE2vjd5Wq2tpDOd7LE/edit#gid=15991859
      TextToSpeech.speak({
        text,
        lang: language,
        rate: 1.0,
        pitch: 1.0,
        volume: 1.0,
        category: 'ambient',
      });
    };
    const speakMobileWithOpenInstallOnce = (text: string) => {
      androidOpenInstallOnce();
      speakMobile(text);
    };
    speakFn = speakMobileWithOpenInstallOnce;
  }

  return {
    speak: speakFn,
  };
}

type I18nextTranslationFunction = TFunction<
  'translation',
  undefined,
  'translation'
>;

function getMultiplierChangeTextToSpeak(
  multiplier: Multiplier,
  score: number,
  t: I18nextTranslationFunction
) {
  const multiplierText =
    // eslint-disable-next-line no-nested-ternary
    multiplier === Multiplier.X2
      ? '2'
      : multiplier === Multiplier.X3
      ? '3'
      : '';
  let text = '';
  if (multiplierText === '')
    text = t('speechSynthesis.multiplierRemoved', { score });
  else text = t('speechSynthesis.multiplierAdded', { multiplier, score });
  return text;
}

export function getLetterMultiplierChangeTextToSpeak(
  letter: string,
  multiplier: Multiplier,
  score: number,
  t: I18nextTranslationFunction
) {
  const multiplierChangeText = getMultiplierChangeTextToSpeak(
    multiplier,
    score,
    t
  );
  const text =
    multiplier !== Multiplier.NONE
      ? `${letter} ${multiplierChangeText}`
      : multiplierChangeText;
  return text;
}

export function getWordMultiplierChangeTextToSpeak(
  multiplier: Multiplier,
  score: number,
  t: I18nextTranslationFunction
) {
  const multiplierChangeText = getMultiplierChangeTextToSpeak(
    multiplier,
    score,
    t
  );
  const text =
    multiplier !== Multiplier.NONE
      ? `${t('speechSynthesis.word')} ${multiplierChangeText}`
      : multiplierChangeText;
  return text;
}

export function getAssignScoreToPlayerTextToSpeak(
  t: I18nextTranslationFunction,
  score: number,
  playerName: string
) {
  return t('speechSynthesis.scoreAssignedToPlayer', { score, playerName });
}
